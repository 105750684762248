import styled from "styled-components";


export const ContactCenterContainer = styled.div`
  display: block;
  position: relative;
  left: 0;
  width: 100%;
  overflow: hidden;
`;
