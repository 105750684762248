import React from 'react';

import { MediumHeaderTypo, BasicParagraphTypo, SubTypo, AllCapsSubTypo } from '../commonStyles/typoTheme';
import { SectionContactMargin } from '../commonStyles/SectionsStyle';
import { MiddleContainer } from '../commonStyles/Containers';
import { TextContainerColumns, SmallColumnsContainer, TextColumn } from '../commonStyles/TextStyles';

import { ContactCenterContainer } from './styles/ContactStyles';



class SectionContact extends React.PureComponent {
  render() {
    return (
          <SectionContactMargin>
            <ContactCenterContainer>
              <MiddleContainer>
                <TextContainerColumns>
                  <TextColumn hasPaddingRight={true}>
                    <MediumHeaderTypo>
                      We’d love to<br /> hear from you
                    </MediumHeaderTypo>
                    <BasicParagraphTypo>
                      If you have an idea for new project, need an exciting rebranding for your company or simply wanna say hi, send us an email.
                    </BasicParagraphTypo>
                  </TextColumn>
                  <SmallColumnsContainer>
                    <TextColumn>
                      <AllCapsSubTypo>
                        PROJECTS
                      </AllCapsSubTypo>
                      <SubTypo>
                        <a href={'mailto:bcc@border3px.com'}>bcc@border3px.com</a>
                      </SubTypo>
                    </TextColumn>
                    <TextColumn>
                      <AllCapsSubTypo>
                        PARTNERSHIP
                      </AllCapsSubTypo>
                      <SubTypo>
                        <a href={'mailto:radoslaw@border3px.com'}>radoslaw@border3px.com</a>
                      </SubTypo>
                    </TextColumn>
                  </SmallColumnsContainer>
                </TextContainerColumns>
              </MiddleContainer>
            </ContactCenterContainer>
          </SectionContactMargin>
    );
  }
}

export default SectionContact;
