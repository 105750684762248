import React from 'react';
import {ThemeProvider} from 'styled-components';


import themes from '../commonStyles/themes';
import { MainContainer, ThemeContainer } from '../commonStyles/Containers';

import SEO from '../components/seo';
import AllFixedElements from '../components/AllFixedElements';
import SectionContact from '../components/SectionContact';
import SectionFooter from '../components/SectionFooter';

import GlobalStyle from '../components/layoutCss';


class ContactPage extends React.PureComponent {

  render() {
    return (
      <>
        <GlobalStyle />
        <ThemeProvider theme={themes.themeInvert}>
          <ThemeContainer isFullHeight={true}>
            <SEO
              title='About'
              path={this.props.path}
            />
            <AllFixedElements current={'contact'}  isCaseStudy={true}  />
            <MainContainer>
              <SectionContact />
              <SectionFooter />
            </MainContainer>
          </ThemeContainer>
        </ThemeProvider>
      </>
    );
  }
}
export default ContactPage;
